@use "mixins" as *
.row
  display: flex
  align-items: flex-start

  > div
    flex: 1

    &:not(:nth-child(1))
      margin-left: 6px

  .field:after
    top: unset
    bottom: 14px

.birthdayRow
  align-items: flex-end

  > div:last-of-type
    min-width: 110px
    flex: 1
  > div:first-of-type
    flex: 3

@media screen and (max-width: 320px) and (orientation: portrait)
  .birthdayRow
    select
      padding: 0 20px 0 6px

    > div:last-of-type
      min-width: 70px

  .row > div
    &:after
      right: 6px

    &:not(:nth-child(1))
      margin-left: 4px

.contentDefault
  composes: contentDefault from "./info/form/layouts/default/default.module.sass"

.btnContainer
  composes: btnContainer from "./info/form/layouts/default/default.module.sass"

.saveIcon
  composes: saveIcon from "./info/form/layouts/default/default.module.sass"

.cancelIcon
  composes: cancelIcon from "./info/form/layouts/default/default.module.sass"
